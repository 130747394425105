// extracted by mini-css-extract-plugin
export var carouselClass = "section-results-map-module--carousel-class--91f65";
export var contentImage = "section-results-map-module--content-image--6f170";
export var contentText = "section-results-map-module--content-text--d2a37";
export var mapContainer = "section-results-map-module--mapContainer--77f24";
export var noPaddingBlock = "section-results-map-module--no-padding-block--b3075";
export var noWrapSearch = "section-results-map-module--no-wrap-search--76bb7";
export var paddingBlock = "section-results-map-module--padding-block--4e144";
export var pageSlide = "section-results-map-module--page-slide--23b27";
export var sectionLeftRight = "section-results-map-module--section-left-right--94f9f";
export var sectionLeftRightContent = "section-results-map-module--section-left-right-content--a61bb";