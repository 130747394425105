import * as React from "react";
import { useLocation} from "@reach/router";
import CmsButtonComponent from "components/cms-button/cms-button.component.js";
import {
    agencyInfoContainer,
    hourContainer,
    bulletPoint,
    openingTime,
    agencyTitle,
    agencyFile,
    descriptionContainer,
    actionsContainer,
    closed,
    bulletPointClosed
} from "./agency-info.module.scss";
import iconPhone from "images/icon-phone.svg";
import iconArrowRight from "images/icon-arrow-right.svg";
import { formatPhone, formatTime } from "../../utils/string-formatters";
import { Link } from "gatsby";
import {computeAgencyOpen} from "../../services/agency.service";

const AgencyInfoComponent = ({ agency, storeLocator }) => {
    const [isClosed, setIsClosed] = React.useState(false);
    const [openWhen, setOpenWhen] = React.useState("");
    const [openHour, setOpenHour] = React.useState("");

    React.useEffect( () => {
        computeAgencyOpenState();
        const intervalId = setInterval(computeAgencyOpenState, 10000);
        return () => clearInterval(intervalId);
    }, []);

    const computeAgencyOpenState = () => {
        const o = computeAgencyOpen(storeLocator);
        setIsClosed(o.isClosed);
        setOpenWhen(o.openWhen);
        setOpenHour(o.openHour);
    }

    const location = useLocation();

    return (
        <div className={agencyInfoContainer}>
            <div className={descriptionContainer}>
               <Link to={`/${agency.attributes.slug}`} state={location.state}>
                   <span className={agencyTitle}>{agency.attributes.nom}</span>
                </Link>
                {
                    isClosed ?
                        <>
                            <div className={hourContainer}>
                                <div className={`${bulletPoint} ${bulletPointClosed}`}/>
                                <div className={`${openingTime} ${closed}`}>
                                    {openWhen}{formatTime(openHour)}
                                </div>
                            </div>
                        </>:
                        <>
                            <div className={hourContainer}>
                                <div className={`${bulletPoint}`}/>
                                <div className={openingTime}>
                                    {`Ouvert jusqu'à ${formatTime(storeLocator.horaireApmFin)}`}
                                </div>
                            </div>
                        </>
                }
                <div className={agencyFile}>
                    <img src={iconArrowRight} alt="Arrow Right" />
                    <Link to={`/${agency.attributes.slug}`} state={location.state}>
                        Fiche agence
                    </Link>
                </div>
            </div>
            <div className={actionsContainer}>
                {!isClosed ? <CmsButtonComponent btnContext={
                    {
                        style : 'outline',
                        color : 'defaut',
                        titre: formatPhone(agency.attributes.telephone),
                        icon: {
                            data: {
                                attributes:{
                                    url:iconPhone,
                                    alternativeText: "appel"
                                }
                            }
                        },
                        url: 'tel:'+agency.telephone,
                        ctaCode: "call-btn"
                    }
                } style={{margin: '0'}}/> : null}
                <CmsButtonComponent btnContext={
                    {
                        style : 'flat',
                        titre: "Prendre rendez-vous",
                        action: "popup",
                        ctaCode: "appointment-btn"
                    }
                } style={{marginTop: '1em'}}/>
            </div>
        </div>
    );
};

export default AgencyInfoComponent;
