import React, { useEffect, useState } from 'react';
import { useLocation} from "@reach/router";
import {
    sectionLeftRight,
    sectionContentText,
    mapContainer,
    noWrapSearch,
    carouselClass,
    paddingBlock,
    noPaddingBlock,
    pageSlide
} from './section-results-map.module.scss';
import CarouselComponent from "components/carousel/carousel.component";
import BreadcrumbComponent from "components/breadcrumb/breadcrumb.component";
import AgencyInfoComponent from "components/agency-info/agency-info.component";
import TagComponent from '../tag/tag.component';
import LazyLoad from 'react-lazyload';
import loadable from '@loadable/component' 
const MapComponent = loadable( () =>  import('../map/map.component'));

const SectionResultsMapComponent = ({ breadcrumbPath, agenciesList, rechercher = "", storeLocator }) => {
    const [slides, setSlides] = useState([]);

    const agencies = agenciesList.map((item, i) => (
        {
            jsx: <AgencyInfoComponent agency={item} key={i} storeLocator={storeLocator} />,
            attributes: item.attributes
        }
    ));

    const location = useLocation();

    useEffect(() => {
        let center = location.state && location.state.selectedCenter ? location.state.selectedCenter : null;
        if(!(center && center[0] && center[1])) center = null;
        const _slides = [];
        const _agencies = sortAgencies(agencies, center);
        while (_agencies.length > 0) {
            _slides.push(
                <div className={pageSlide}>{_agencies.splice(0, 2)}</div>
            );
        }
        setSlides(_slides);
    }, [agenciesList])

    return (
        <section className={sectionLeftRight}>
            <div>
                <BreadcrumbComponent path={breadcrumbPath} />
                <div className={"block "+noPaddingBlock}>
                    <div className={paddingBlock}>
                        <TagComponent title="RECHERCHER UNE AGENCE" />
                        <h3 className="titre">{agenciesList.length > 1 ? agenciesList.length+ " agences":"1 agence"} Paritel en <span className={noWrapSearch}>{rechercher}</span></h3>
                        <p className={sectionContentText}>
                            Prenez RDV avec un de nos experts afin de bénéficier d’un bilan gratuit.
                            Nos conseillers se déplacent directement sur votre lieu de travail.
                        </p>
                    </div>
                    <CarouselComponent slides={slides} className={carouselClass}/>
                </div>
            </div>
            <div className={mapContainer}>
                <LazyLoad once offset={100}>
                    <MapComponent agenciesList={agenciesList}/>
                </LazyLoad>
            </div>
        </section>
    );
}

export default SectionResultsMapComponent;


function sortAgencies(agencies, center) {
    if(center) agencies = agencies.sort((a, b) => {
        if (calculateDistance(center[0], b.attributes.longitude, center[1], b.attributes.latitude)
            > calculateDistance(center[0], a.attributes.longitude, center[1], a.attributes.latitude)) {
            return -1;
        }

        if (calculateDistance(center[0], b.attributes.longitude, center[1], b.attributes.latitude)
            < calculateDistance(center[0], a.attributes.longitude, center[1], a.attributes.latitude)) {
            return 1;
        }

        return 0;
    });
    return agencies.map( a => a.jsx);
}

function calculateDistance(x1, x2, y1, y2) {
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
}