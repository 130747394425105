
export const computeAgencyOpen = (storeLocator) => {
    const currentDate = new Date();
    const hour = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const weekDay = currentDate.getDay();
    let currentTime = (hour < 10 ? "0"+hour : hour) + ':' + (minutes < 10 ? "0"+minutes : minutes);

    if(weekDay === 6) return {
        isClosed: true,
        openWhen: "Ouvre lundi à ",
        openHour: storeLocator.horaireMatinDebut
    }
    else if(weekDay === 0)  return {
        isClosed:true,
        openWhen:"Ouvre demain à ",
        openHour:storeLocator.horaireMatinDebut,
    }
    else if(currentTime >= storeLocator.horaireMatinFin &&
        currentTime <= storeLocator.horaireApmDebut) return {
        isClosed:true,
        openWhen:"Fermée, ouvre à ",
        openHour:storeLocator.horaireApmDebut,
    }
    else if(currentTime < storeLocator.horaireMatinDebut) return {
        isClosed:true,
        openWhen:"Fermée, ouvre à ",
        openHour:storeLocator.horaireMatinDebut,
    }
    else if(currentTime > storeLocator.horaireApmFin) return {
        isClosed:true,
        openWhen:"Ouvre demain à ",
        openHour:storeLocator.horaireMatinDebut,
    }
    return {
        isClosed: false,
        openHour: "",
        openWhen: ""
    }
}