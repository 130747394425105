import * as React from "react";
import { useEffect } from "react";
import { graphql } from "gatsby";
import SectionResultsMapComponent from "components/section-results-map/section-results-map.component";
import { buildSections } from "../../services/sections";
import {Seo} from "../../components/seo/seo";


const Region = ({ data, pageContext }) => {
    const pageData = data.cms.pages.data[0].attributes;
    const sections = pageData.sections;
    const storeLocator = data.cms.storeLocator.data.attributes;
    const breadcrumbPath = [
        {title:'Localiser une agence', url:'/'},
        {title:data.cms.departements.data[0].attributes.region},
    ];

    useEffect(() => {
      let url = window.location.href.split("#");
      let target = url[url.length - 1].toLowerCase();
      let element = document.getElementById(target);
      element && element.scrollIntoView({ behavior: "smooth", block: "start"});
    }, []);

    return (
        <div className="container-fluid">
          <Seo pageContext={pageContext} pageData={pageData} />
          <div id="agence">
          <SectionResultsMapComponent breadcrumbPath={breadcrumbPath} agenciesList={data.cms.agences.data} rechercher={pageContext.region} storeLocator={storeLocator} />
          {
            buildSections(sections, null, null, null, [], 0)
          }
          </div>
        </div>
  )
};

export default Region;

export const query = graphql`
query regionAgencesQuery($slugRegion: String) {
    cms {
        departements(
          filters: {slugRegion: {eq: $slugRegion}}
          pagination: {limit: 1}
        ) {
          data {
            attributes {
              region
            }
          }
        }
      agences(filters: {departements: {slugRegion: {eq: $slugRegion}}}) {
        data {
          attributes {
            latitude
            longitude
            nom
            telephone
            slug
            geojson
          }
        }
      }
      pages(filters: {name: {eq: "storelocator/region"}}) {
        data {
          attributes {
            sections {
              __typename
              ... on CMS_ComponentStoreLocatorSectionTexteImage {
                id
                bouton {
                  titre
                  style
                }
                slide {
                  id
                  tag
                  texte
                  titre
                }
                image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
              }
              ... on CMS_ComponentStoreLocatorSectionCarteAgences {
                id
              }
            }
            seoMetaDescription
            seoMetaTitle
          }
        }
      }
      storeLocator {
        data {
          attributes {
            horaireApmDebut
            horaireApmFin
            horaireMatinDebut
            horaireMatinFin
          }
        }
      }
    }
  }
`;